import { createSelector } from '@ngrx/store';
import { paymentsDetail } from '../../payments.page/store';

export const registryAVR = 'registryAVR';
export const registriesDetail = 'registriesDetail';

export const registryAVRFeature = (state: any) => state[registryAVR];

export const selectRegistryAvr = createSelector(
  registryAVRFeature,
  (state) => state,
);
export const selectRegistryAvrList = createSelector(
  registryAVRFeature,
  ({ list }: any) => list,
);
export const selectRegistryAvrUpload = createSelector(
  registryAVRFeature,
  ({ upload }: any) => upload,
);
export const selectRegistryAvrLoading = createSelector(
  registryAVRFeature,
  ({ hasLoaded, isPending }: any) => !hasLoaded || isPending,
);
export const selectRegistryAvrPagination = createSelector(
  registryAVRFeature,
  ({ pagination }: any) => pagination,
);

export const registriesDetailFeature = (state: any) => state[registriesDetail];

export const selectRegistriesDetail = createSelector(
  registriesDetailFeature,
  (state: any) => state,
);
export const selectRegistriesDetailRegistry = createSelector(
  selectRegistriesDetail,
  ({ registry }: any) => registry,
);
export const selectRegistriesDetailList = createSelector(
  selectRegistriesDetail,
  ({ list }: any) => list,
);
export const selectRegistriesDetailLoading = createSelector(
  selectRegistriesDetail,
  ({ hasLoaded, isPending }: any) => !hasLoaded || isPending,
);
export const selectRegistriesDetailPagination = createSelector(
  selectRegistriesDetail,
  ({ pagination }: any) => pagination,
);
