import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RegistriesApiService } from '@common/services';
import {
  ActSend,
  ActSendError,
  ActSendStart,
  ActSendStartError,
  ActSendStartSuccess,
  ActSendSuccess,
  RegistriesDetailLoad,
  RegistriesDetailLoadError,
  RegistriesDetailLoadSuccess,
  RegistriesUpload,
  RegistriesUploadError,
  RegistriesUploadProgress,
  RegistriesUploadSuccess,
  RegistryAvrLoad,
  RegistryAvrLoadError,
  RegistryAvrLoadSuccess,
} from './actions';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectRegistriesDetail } from './selectors';
import { Router } from '@angular/router';
import { NotificationAdd, selectProfile } from '../../store';

@Injectable()
export class RegistriesEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly api = inject(RegistriesApiService);
  private readonly router = inject(Router);

  public loadRegistries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistryAvrLoad),
      exhaustMap(({ page }) =>
        this.api.loadRegistryAvr(page).pipe(
          map(({ registry, pagination }: any) =>
            RegistryAvrLoadSuccess({ registry, pagination }),
          ),
          catchError((error) => of(RegistryAvrLoadError({ error }))),
        ),
      ),
    ),
  );

  public loadDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistriesDetailLoad),
      exhaustMap(({ registry, page }) =>
        this.api.loadRegistryDetail(registry.id, page).pipe(
          map(({ payments, pagination }: any) =>
            RegistriesDetailLoadSuccess({ payments, pagination }),
          ),
          catchError((error) => of(RegistriesDetailLoadError({ error }))),
        ),
      ),
    ),
  );

  public readonly upload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistriesUpload),
      exhaustMap(({ file }) =>
        this.api.upload(file).pipe(
          tap((event) =>
            event.type === HttpEventType.UploadProgress
              ? this.store.dispatch(
                  RegistriesUploadProgress({
                    loaded: event.loaded,
                    total: event.total,
                  }),
                )
              : null,
          ),
          filter((event) => event.type === HttpEventType.Response),
          tap(() => this.store.dispatch(RegistriesUploadSuccess())),
          map(() => RegistryAvrLoad({ page: 1 })),
          catchError((error) => of(RegistriesUploadError({ error }))),
        ),
      ),
    ),
  );

  public paymentsSendStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActSendStart),
      exhaustMap(() =>
        this.sendStartWizard().pipe(
          map(({ requestID }: any) => ActSendStartSuccess({ requestID })),
          catchError((error) => of(ActSendStartError({ error }))),
        ),
      ),
    ),
  );

  private sendStartWizard() {
    return this.store.select(selectProfile).pipe(
      take(1),
      switchMap(({ email }) => this.api.sendStart(email)),
    );
  }

  public paymentsSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActSend),
      exhaustMap(({ code }) =>
        this.store.select(selectRegistriesDetail).pipe(
          take(1),
          switchMap(({ requestID, registry }) =>
            this.api
              .sendComplete({ requestID, otp: code })
              .pipe(switchMap(() => this.api.sendAction(registry.id))),
          ),
          tap(() => {
            this.store.dispatch(RegistryAvrLoad({ page: 1 }));
            void this.router.navigate(['/', 'cabinet', 'registries', 'abp']);
          }),
          map(() => ActSendSuccess()),
          catchError((error) => {
            this.store.dispatch(ActSendError());
            console.log(error);
            return of(NotificationAdd({ message: { error } }));
          }),
        ),
      ),
    ),
  );
}
