import { createReducer, on } from '@ngrx/store';
import { registriesDetail, registryAVR } from './selectors';
import {
  ActDetailSetRegistry,
  ActSendStartSuccess,
  RegistriesDetailLoad,
  RegistriesDetailLoadError,
  RegistriesDetailLoadSuccess,
  RegistriesDetailReset,
  RegistriesUpload,
  RegistriesUploadError,
  RegistriesUploadProgress,
  RegistriesUploadSuccess,
  RegistryAvrLoad,
  RegistryAvrLoadError,
  RegistryAvrLoadSuccess,
} from './actions';

const registryAvrInitialState: any = {
  list: [],
  hasLoaded: false,
  isPending: false,
  error: null,
  upload: {
    error: null,
    loaded: 0,
    total: 0,
    fileName: null,
    isPending: false,
  },
};

export const registryAvrReducer = createReducer(
  registryAvrInitialState,
  on(RegistryAvrLoad, (state) => ({
    ...state,
    hasLoaded: false,
    isPending: true,
  })),
  on(RegistryAvrLoadSuccess, (state, { registry, pagination }) => ({
    ...state,
    list: registry,
    pagination,
    hasLoaded: true,
    isPending: false,
  })),
  on(RegistryAvrLoadError, (state, { error }) => ({
    ...state,
    error,
    hasLoaded: true,
    isPending: false,
  })),
  on(RegistriesUpload, (state, { fileName, total }) => ({
    ...state,
    upload: {
      ...state.upload,
      fileName,
      total,
      isPending: true,
      error: null,
    },
  })),
  on(RegistriesUploadProgress, (state, { total, loaded }) => ({
    ...state,
    upload: {
      ...state.upload,
      total,
      loaded,
    },
  })),
  on(RegistriesUploadSuccess, (state) => ({
    ...state,
    upload: {
      ...registryAvrInitialState.upload,
    },
  })),
  on(RegistriesUploadError, (state, { error }) => ({
    ...state,
    upload: {
      ...state.upload,
      isPending: false,
      error,
    },
  })),
);

const registriesDetailInitialState: any = {
  isPending: false,
  hasLoaded: false,
  registry: null,
  list: [],
  error: null,
  pagination: null,
  requestID: null,
};

export const registriesDetailReducer = createReducer(
  registriesDetailInitialState,
  on(RegistriesDetailLoad, (state, { registry }) => ({
    ...state,
    registry,
    hasLoaded: false,
    isPending: true,
  })),
  on(RegistriesDetailLoadSuccess, (state, { payments, pagination }) => ({
    ...state,
    list: payments,
    pagination,
    hasLoaded: true,
    isPending: false,
  })),
  on(RegistriesDetailLoadError, (state, { error }) => ({
    ...state,
    error,
    hasLoaded: true,
    isPending: false,
  })),
  on(RegistriesDetailReset, (_state) => ({
    ...registriesDetailInitialState,
  })),
  on(ActDetailSetRegistry, (state, { registry }) => ({
    ...state,
    registry,
  })),
  on(ActSendStartSuccess, (state, { requestID }) => ({
    ...state,
    requestID,
  })),
);

export const registriesReducers = {
  [registryAVR]: registryAvrReducer,
  [registriesDetail]: registriesDetailReducer,
};
