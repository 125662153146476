import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const RegistryAvrLoad = createAction(
  '[Registries Page] Avr Load',
  props<{ page?: number }>(),
);
export const RegistryAvrLoadSuccess = createAction(
  '[Registries Page] Avr Load Success',
  props<{ registry: any[]; pagination: any }>(),
);
export const RegistryAvrLoadError = createAction(
  '[Registries Page] Avr Load Error',
  props<{ error: HttpErrorResponse }>(),
);
export const RegistryAvrReset = createAction('[Registries Page] Avr Reset');

export const RegistriesUpload = createAction(
  '[Registries Page] Upload',
  props<{
    file: Blob;
    fileName: string;
    total: number;
  }>(),
);
export const RegistriesUploadSuccess = createAction(
  '[Registries Page] Upload Success',
);
export const RegistriesUploadError = createAction(
  '[Registries Page] Upload Error',
  props<{ error: HttpErrorResponse }>(),
);
export const RegistriesUploadProgress = createAction(
  '[Registries Page] Upload Progress',
  props<{ loaded: number | undefined; total: number | undefined }>(),
);

export const RegistriesDetailLoad = createAction(
  '[Registries Page] Detail Load',
  props<{ registry: any; page?: number }>(),
);
export const RegistriesDetailLoadSuccess = createAction(
  '[Registries Page] Detail Load Success',
  props<{ payments: any[]; pagination: any }>(),
);
export const RegistriesDetailLoadError = createAction(
  '[Registries Page] Detail Load Error',
  props<{ error: HttpErrorResponse }>(),
);
export const RegistriesDetailReset = createAction(
  '[Registries Page] Detail Load Error',
);

export const ActDetailSetRegistry = createAction(
  '[Registries Page] Act Set Registry',
  props<{ registry: any }>(),
);
export const ActSendStart = createAction('[Registries Page] Act Send Start');
export const ActSendStartSuccess = createAction(
  '[Registries Page] Act Send Start Success',
  props<{ requestID: string }>(),
);
export const ActSend = createAction(
  '[Registries Page] Act Send',
  props<{ code: string }>(),
);
export const ActSendSuccess = createAction(
  '[Registries Page] Act Send Success',
);
export const ActSendError = createAction('[Registries Page] Act Send Error');
export const ActSendStartError = createAction(
  '[Registries Page] Act Send Start Error',
  props<{ error: HttpErrorResponse }>(),
);
